
import { defineComponent, PropType, ref } from 'vue';
import { OrderData } from '@/components/view/common/payment/all.type';
import initCard from '@/components/view/common/payment/card';
import detailDialog from './more-dialog.vue';

export default defineComponent({
    components: {
        detailDialog
    },
    props: {
        orderData: {
            type: Object as PropType<OrderData>,
            required: true
        },
        projectType: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const {
            mainPrice,
            subPrice,
            pmPrice,
            isShowMoreDialog,
            more,
            moreData
        } = initCard(props.orderData);

        return {
            mainPrice,
            subPrice,
            pmPrice,
            isShowMoreDialog,
            more,
            moreData
        };
    }
});
