import { ref } from 'vue';
import { OrderData } from '@/components/view/common/payment/all.type';
import { orderType } from '@/data';
import { discountUnitEx100, discountUnitTo100 } from '@/methods/count';

const initCard = (
    orderData: OrderData
) => {
    // 计算数据
    const mainPrice = ref(0);
    const subPrice = ref(0);
    const pmPrice = ref(0);

    orderData.main.forEach((item) => {
        mainPrice.value += item.Price;
    });
    mainPrice.value = discountUnitEx100(discountUnitTo100(mainPrice.value));

    orderData.sub.forEach((item) => {
        subPrice.value += item.Price;
    });

    subPrice.value = discountUnitEx100(discountUnitTo100(subPrice.value));

    orderData.pmApp.forEach((item) => {
        pmPrice.value += item.Price;
    });

    pmPrice.value = discountUnitEx100(discountUnitTo100(pmPrice.value));

    // 更多弹窗控制及数据
    const isShowMoreDialog = ref(false);
    const moreData = ref<orderType.OrderInfoChildren>();
    const more = (item: orderType.OrderInfoChildren) => {
        moreData.value = item;
        isShowMoreDialog.value = true;
    };

    return {
        mainPrice,
        subPrice,
        pmPrice,
        isShowMoreDialog,
        more,
        moreData
    };
};
export default initCard;